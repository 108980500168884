import React, { useEffect, useReducer, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AddCircle from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import HouseDetail from "../HouseDetail";
import CsvConvert from "react-json-to-csv";
import uniqueId from "lodash/uniqueId";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import { fetchData } from "../../utils/fetchData";

const WhereIsTable = ({
  movie_id,
  startdate,
  enddate,
  country,
  imax,
  m3d,
  shouldSearch,
  handleHasSearched,
}) => {
  const [selectedHouseId, setSelectedHouseId] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [data, dispatch] = useReducer(
    (_, action) => {
      switch (action.type) {
        case "INSERT":
          return { rows: action.data, loading: false, error: null };
        case "LOADING":
          return { rows: [], loading: true, error: null };
        case "ERROR":
          return { rows: [], loading: false, error: action.error };
        default:
          throw Error("Unknown action.");
      }
    },
    { rows: [], loading: false, error: null },
  );

  useEffect(() => {
    if (!movie_id || !shouldSearch) {
      return;
    }
    dispatch({ type: "LOADING" });
    fetchData({
      endpoint: "whereis",
      body: {
        movie_id,
        startdate,
        enddate,
        country,
        imax: imax ? 1 : 0,
        m3d: m3d ? 1 : 0,
      },
      handleComplete: (response) => {
        dispatch({ type: "INSERT", data: response.data });
      },
      handleError: (response) => {
        dispatch({ type: "ERROR", error: response.error });
      },
      handleFinally: () => {
        handleHasSearched();
      },
    });
  }, [
    movie_id,
    startdate,
    enddate,
    country,
    imax,
    m3d,
    shouldSearch,
    handleHasSearched,
  ]);

  useEffect(() => {
    if (!data.rows.length) {
      return;
    }
    setCsvData(
      data.rows.reduce(
        (houses, country) => [
          ...houses,
          ...country.chains.reduce(
            (chainHouses, chain) => [...chainHouses, ...chain.houses],
            [],
          ),
        ],
        [],
      ),
    );
  }, [data.rows]);

  return (
    <div>
      {!!selectedHouseId && (
        <HouseDetail
          open
          house_id={selectedHouseId}
          onClose={() => setSelectedHouseId(null)}
          fullScreen={false}
        />
      )}
      {data.loading && (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <span className="glyphicon-left glyphicon glyphicon-refresh spinning">
            <CircularProgress />
          </span>
        </div>
      )}
      {!!data.error && (
        <span style={{ color: "#FF0000", fontSize: "1.5rem" }}>
          {data.error}
        </span>
      )}
      <Grid
        item
        xs={12}
        style={{ marginLeft: 30, marginRight: 30, padding: 10 }}
      >
        <Box>
          <p
            style={{
              fontWeight: "bold",
              textAlign: "right",
              marginTop: "25",
            }}
          >
            Total Houses: {uniqBy(csvData, "houseId").length}
            <br />
            Total Screen:{" "}
            {uniqBy(csvData, "houseId").reduce(
              (total, house) => total + house.screenCount,
              0,
            )}
          </p>
        </Box>
        <CsvConvert
          data={csvData}
          filename="report.csv"
          style={{
            borderRadius: "3px",
            border: "1px solid black",
            display: "inline-block",
            cursor: "pointer",
            fontSize: "12px",
            color: "black",
            fontWeight: "bold",
            padding: "6px 24px",
            textDecoration: "none",
          }}
        >
          Export to CSV
        </CsvConvert>
      </Grid>
      {orderBy(data.rows, (country) => country.chains.length, "desc").map(
        (country) => (
          <div
            key={uniqueId("country")}
            style={{ marginLeft: 30, marginRight: 30, padding: 10 }}
          >
            <h2>{country.code}</h2>
            {orderBy(
              country.chains,
              (chain) => chain.totalHouseCount || 0,
              "desc",
            ).map((chain) => (
              <Grid
                item
                xs={12}
                xl={12}
                sx={12}
                sm={12}
                key={uniqueId("chain")}
              >
                <Divider />
                <React.Fragment>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <IconButton
                        href={"/schedule/" + chain.id}
                        target="_blank"
                      >
                        <AddCircle />
                      </IconButton>
                      <Typography
                        style={{
                          width: "33%",
                          flexShrink: 0,
                          color: "black",
                          fontWeight: "bold",
                          marginTop: "11px",
                        }}
                      >
                        Total House:{" "}
                        {chain.totalHouseCount
                          ? `${uniqBy(chain.houses, "houseId").length} / ${
                              chain.totalHouseCount
                            }`
                          : chain.houses.length}
                      </Typography>

                      <Typography
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          marginTop: "11px",
                        }}
                      >
                        {chain.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table style={{ whiteSpace: "nowrap" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Movie</TableCell>
                            <TableCell style={{ width: "25%" }} align="left">
                              House
                            </TableCell>
                            <TableCell>House ID</TableCell>
                            <TableCell style={{ width: "5%" }} align="left">
                              AltId
                            </TableCell>
                            <TableCell align="left">Screen</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>State</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {chain.houses.map((house) => (
                            <TableRow key={uniqueId("house")}>
                              <TableCell>
                                <IconButton
                                  onClick={() =>
                                    setSelectedHouseId(house.houseId)
                                  }
                                  align="right"
                                >
                                  <InfoIcon />
                                </IconButton>
                                <IconButton
                                  href={
                                    chain.id
                                      ? `/schedule/${chain.id}/${house.houseId}`
                                      : `/schedule/${house.houseId}`
                                  }
                                  target="_blank"
                                >
                                  <CalendarTodayIcon />
                                </IconButton>
                                {chain.id ? (
                                  <IconButton
                                    href={`/schedule/${chain.id}`}
                                    target="_blank"
                                  >
                                    <AddCircle />
                                  </IconButton>
                                ) : null}
                              </TableCell>
                              <TableCell title={house.movieName}>
                                {house.movieName}
                              </TableCell>
                              <TableCell
                                title={house.houseName}
                                style={{ fontWeight: "bold" }}
                                align="left"
                              >
                                {house.houseName}
                              </TableCell>
                              <TableCell>{house.houseId}</TableCell>
                              <TableCell
                                title={house.altId}
                                style={{ fontWeight: "bold" }}
                              >
                                {house.altId}
                              </TableCell>
                              <TableCell>{house.screenCount}</TableCell>
                              <TableCell>{house.city}</TableCell>
                              <TableCell>{house.state}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </React.Fragment>
              </Grid>
            ))}
          </div>
        ),
      )}
    </div>
  );
};

export default WhereIsTable;
